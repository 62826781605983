@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  font-size: 10px;
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

ul {
  list-style: unset;
}
ol {
  list-style: auto;
}

input:-webkit-autofill,
input:-webkit-autofill:focus {
  transition: background-color 600000s 0s, color 600000s 0s;
}
input[data-autocompleted] {
  background-color: transparent !important;
}

/*Input*/
.mantine-Input-invalid {
  color: black;
}
.mantine-Input-invalid:focus {
  border-color: #fa5252;
  color: black;
}
.mantine-Input-invalid::placeholder {
  color: #ced4da;
}

.mantine-PasswordInput-invalid::placeholder {
  color: #ced4da;
}
.mantine-Input-wrapper > .mantine-PasswordInput-invalid:focus-within {
  border-color: #fa5252;
}
.mantine-PasswordInput-invalid {
  color: black;
}

/*Modal*/
.mantine-Modal-title {
  font-size: 22px;
}
.mantine-Modal-close {
  width: 30px;
  height: 30px;
}
.mantine-Modal-close > svg {
  height: 22px;
  width: 22px;
}
/*Select*/
.mantine-Select-input {
  height: 42px;
}

/*Date picker(fix increment paddings in DatePicker)*/
.mantine-DatePicker-month > tbody > tr > td:first-child {
  padding: 0;
}
.mantine-DatePicker-monthPickerControlActive {
  background: #228be6;
}
.mantine-DatePicker-yearPickerControlActive {
  background: #228be6;
}
